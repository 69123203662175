import React from 'react'
import { ThemeProvider } from 'styled-components'
import Sticky from 'react-stickynode'

import { raveniiTheme } from '../theme/ravenii'
import { ResetCSS } from '../assets/css/style'
import { GlobalStyle, ContentWrapper } from '../components/layout/container.style'

import { DrawerProvider } from '../components/common/contexts/DrawerContext'
import Seo from '../components/seo'
import Navbar from '../components/layout/Navbar'
import SupportLandingSection from '../components/containers/SupportLanding'
import ContactUsSection from '../components/containers/ContactUs'
import Footer from '../components/layout/Footer'

const SupportPage = () => {
  return (
    <ThemeProvider theme={raveniiTheme}>
      <Seo title='RAVENii | Support' />

      <ResetCSS />
      <GlobalStyle />

      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
        </Sticky>

        <SupportLandingSection />
        <ContactUsSection />

        <Footer />
      </ContentWrapper>
    </ThemeProvider>
  )
}

export default SupportPage
