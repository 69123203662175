import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Parallax } from 'react-parallax'
import LightSpeed from 'react-reveal/LightSpeed'
import { FiExternalLink } from 'react-icons/fi'
import {
  ParallaxImageTextOverlayWrapper,
  ParallaxImageWrapper,
  SupportItemRow,
  SupportItemSection,
  SupportItemSectionTitle,
  OpenArticleButton,
  SupportItemSectionContent,
} from './supportLanding.style'

import headerParallaxImage from '../../../images/support-page-header-parallax.jpg'

const renderSupportItemSection = (supportItem, articlePath) => {
  if (supportItem.document) {
    return (
      <div>
        <OpenArticleButton>
          <button>
            Open <FiExternalLink size={15} />
          </button>
        </OpenArticleButton>
        <SupportItemSectionContent>
          <object width='325' height='325' type='application/pdf' data={articlePath}>
            <p>Your browser does not support embedded PDF documents. Click to open the PDF.</p>
          </object>
        </SupportItemSectionContent>
      </div>
    )
  } else if (supportItem.href) {
    return (
      <OpenArticleButton>
        <button style={{ marginBottom: '15px' }}>
          Open <FiExternalLink size={15} />
        </button>
      </OpenArticleButton>
    )
  } else {
    console.error('Unsupported field to parse support item.')
  }
}

const SupportLandingSection = () => {
  const getSupportItemSection = supportItem => {
    const supportItemId = `supportItem${supportItem.title.toLowerCase().replace(/\s/g, '')}`
    let articlePath
    if (supportItem.document) {
      articlePath = `/documents/${supportItem.document}?#zoom=65&toolbar=0`
    } else if (supportItem.href) {
      articlePath = supportItem.href
    } else {
      console.error('Unsupported field to parse support item.')
    }

    return (
      <SupportItemRow key={supportItemId}>
        <SupportItemSection>
          <a href={articlePath} target='_blank' rel='noopener noreferrer'>
            <SupportItemSectionTitle>
              <LightSpeed>
                <div>{supportItem.title}</div>
              </LightSpeed>
              <div className='support-item__publish-date'>{supportItem.publishDate}</div>
            </SupportItemSectionTitle>
            {renderSupportItemSection(supportItem, articlePath)}
          </a>
        </SupportItemSection>
      </SupportItemRow>
    )
  }

  // const Data = useStaticQuery(graphql`
  //     query {
  //         dataJson {
  //             SUPPORT_PAGE {
  //                 headerImageOverlayText
  //                 supportItems {
  //                     title
  //                     document
  //                     publishDate
  //                 }
  //             }
  //         }
  //     }
  // `);
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        SUPPORT_PAGE {
          headerImageOverlayText
        }
      }
    }
  `)

  const supportPageData = Data.dataJson.SUPPORT_PAGE[0]

  return (
    <section>
      <Parallax bgImage={headerParallaxImage} strength={350}>
        <ParallaxImageWrapper>
          <ParallaxImageTextOverlayWrapper>
            <LightSpeed>
              <div>{supportPageData.headerImageOverlayText}</div>
            </LightSpeed>
          </ParallaxImageTextOverlayWrapper>
        </ParallaxImageWrapper>
      </Parallax>

      <div>
        {supportPageData?.supportItems
          ? supportPageData.supportItems
              .sort((x, y) => {
                const xDateValue = Date.parse(x.publishDate)
                const yDateValue = Date.parse(y.publishDate)
                if (xDateValue < yDateValue) {
                  return -1
                }
                if (xDateValue > yDateValue) {
                  return 1
                }
                return 0
              })
              .reverse()
              .map(item => getSupportItemSection(item))
          : null}
      </div>
    </section>
  )
}

export default SupportLandingSection
