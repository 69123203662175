import styled from 'styled-components';
import BannerBG from '../../../assets/image/banner-texture.png';

export const ParallaxImageTextOverlayWrapper = styled.section`
    color: #ffffff;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.025em;
    text-align: center;
    padding: 15px 40px 15px 40px;
    background-image: url(${BannerBG}),
    linear-gradient(35deg, rgba(99, 23, 25, 0.7) 0%, rgba(176, 58, 62, 0.7) 100%);
    width: 100%;

    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%,-50%);
`;

export const ParallaxImageWrapper = styled.div`
    height: 350px;
`;

export const SupportItemRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SupportItemSection = styled.div`
    min-height: 100px;
    width: 500px;
    
    margin: 1.25rem 1.25rem 0 1.25rem;
    text-align: center;

    border-radius: .19571429rem;
    box-shadow: 0 1px 1px 1px #d4d4d5, 0 0 0 1px #d4d4d5;

    a {
        color: inherit;
    }
`;

export const SupportItemSectionTitle = styled.div`
    color: #ffffff;
    text-align: center;
    font-size: 1.50rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.025em;
    padding: 15px 40px 15px 40px;
    margin: 0;
    background-image: url(${BannerBG}),
    linear-gradient(45deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.7) 75%);

    .support-item__publish-date {
        text-align: right;
        font-size: 1.25rem;
        font-style: italic;
        font-weight: 500;
    }
`;

export const OpenArticleButton = styled.div`
    margin: auto;
    margin-top: 1rem;

    button {
        height: 100%;
        width: 325px;
        background-color: #edcd36;
        font-size: 1rem;
        text-align: center;
        border-radius: 4px;
    }

    button:hover {
        cursor: pointer;
    }
`;

export const SupportItemSectionContent = styled.div`
    padding: 1rem;

    .support-item__tagline {
        margin-top: 1rem;
        font-size: 1.50rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -0.025em;
        
    }
`;